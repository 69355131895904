<template>
  <div id="app">
    <router-view/>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <RoomList /> -->
  </div>
</template>

<script>
// import RoomList from './components/RoomList.vue'

export default {
  name: 'App',
  // components: {
  //   RoomList
  // },
}
</script>

<style>

html {
  background-color: #f2f2f2;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Index',
      meta: {
        title: '陈列室语音讲解'
      },
      component: () => import(/* webpackChunkName: "list" */'./views/List.vue')
    }, {
      path: '/detail/:id',
      name: 'detail',
      meta: {
        title: '详情'
      },
      component: () => import(/* webpackChunkName: "user" */'./views/Detail.vue')
    }
  ]
})
